// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  ssoLogin: false,
  google_client_id: '385817871666-euvqvasd3ul6k5l2uur6fkh3bl6lpu52.apps.googleusercontent.com',
  kl_domains: ['knowledgelens.com', 'glenslabs.com', 'unifytwin.com'],
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
