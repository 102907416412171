import { Component } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { Observable, interval } from 'rxjs';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'site-commissioning';

  constructor(public updates: SwUpdate) {
    if (updates.isEnabled) {
      interval(6 * 60 * 60 * 10).subscribe(() => updates.checkForUpdate().then(() => console.log('checking for updates')));
    }
    updates.available.subscribe(event => {
      console.log('checkForUpdates event: ', event);
      updates.activateUpdate().then(() => {
        if (confirm(`A new version of app is available. Please click 'OK' to reload with latest version.`)) {
          document.location.reload();
        }
      });
    });
  }
}
